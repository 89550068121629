import React from "react";
import {
  Classes,
  Card,
  H2,
  Callout,
  H4,
  AnchorButton,
  H3,
  HTMLTable,
} from "@blueprintjs/core";

import { Feature } from "../../../repositories/firebase/FeaturesRepository";

const ZIGEXNIST_INQUIRY_FORM_URL = "https://forms.gle/xAMz3kQb8akG3C9b6";

interface Props {
  features: Feature[];
}

class InquiryUI extends React.Component<Props, {}> {
  render() {
    return (
      <section className={Classes.DARK + " inquiry"}>
        <Card className="inquiry-main-card">
          <H2 className="inquiry-form-title">
            お問い合わせ・機能追加要望送信フォーム
          </H2>
          <Callout>
            <H4>新ZIGExNISTに対しての要望・バグ報告はこちらから</H4>
            下記ボタンよりGoogleフォームに遷移可能ですので、そちらからご連絡ください
            <div className="inquiry-form-url">
              <AnchorButton
                href={ZIGEXNIST_INQUIRY_FORM_URL}
                intent="primary"
                large
                target="_blank"
              >
                要望・バグ報告はこちらから
              </AnchorButton>
            </div>
          </Callout>

          <H3 className="inquiry-feature-list-title">
            要望受信済み・実装予定リスト
          </H3>
          <Callout intent="primary">
            「要望受信済み・実装予定リスト」は、皆さまよりご連絡いただいた内容をZIGExNIST管理人側で確認したもののリストです。
            <br />
            こちらのリストに入っているものは順次実装を進めていきますので、実装完了までしばらくお待ち下さい。
          </Callout>

          <HTMLTable className="inquiry-table" striped bordered>
            <thead>
              <tr>
                <th className="inquiry-table-title">機能名</th>
                <th className="inquiry-table-about">概要</th>
                <th className="inquiry-table-priority">実装優先度</th>
              </tr>
            </thead>
            <tbody>
              {this.props.features &&
                this.props.features.map((feature: Feature) => {
                  return (
                    <tr key={feature.title}>
                      <td className="inquiry-table-title">{feature.title}</td>
                      <td className="inquiry-table-about">{feature.about}</td>
                      <td className="inquiry-table-priority">
                        {feature.priority}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </HTMLTable>
        </Card>
      </section>
    );
  }
}

export default InquiryUI;
