// RepositoryFactoryパターンについて: https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
import axios from "axios";

import * as firebase from "firebase/app";
import "firebase/firestore";

export interface ResponseBase {
  data?: any;
  status?: number;
  statusText?: string;
}

// APIのアクセス先情報
const baseDomain = process.env.REACT_APP_HOST;
const baseURL = `${baseDomain}/api/v1`;

export let Repository = axios.create({ baseURL });

export let FirebaseRepository: firebase.firestore.Firestore = firebase.firestore();
export let FirebaseTimestamp = firebase.firestore.Timestamp;
