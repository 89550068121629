import React from "react";
import { Callout, AnchorButton } from "@blueprintjs/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
interface Props extends RouteComponentProps {
  hasGat: Boolean | null;
  sentUser: String | null;
}
interface State {}

class GatSendButtons extends React.Component<Props, State> {
  render() {
    const MONTH_OFFSET = 1;
    let month = new Date().getMonth() + MONTH_OFFSET; //getMonthは0~11を返すのでoffsetを足す必要がある
    if (this.props.hasGat) {
      return (
        <AnchorButton
          intent="primary"
          fill
          large
          onClick={() => this.props.history.push("/send")}
        >
          {month}月分のGATを送信する
        </AnchorButton>
      );
    } else if (this.props.sentUser !== null) {
      return (
        <Callout intent="success">
          GATは{this.props.sentUser}さんに送信済みです！
        </Callout>
      );
    } else {
      return <Callout intent="danger">GATの送信状況を取得できません</Callout>;
    }
  }
}

export default withRouter(GatSendButtons);
