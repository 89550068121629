import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Navbar, Alignment } from "@blueprintjs/core";

import UserMenu from "./userMenu";
import firebase from "../../../src/firebase";
import Axios from "axios";
interface Props extends RouteComponentProps {
  loginHundler: any; // FIXME
  logoutHundler: any; // FIXME
}

interface State {
  isAdmin: boolean;
}

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAdmin: false,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        Axios.post(process.env.REACT_APP_HOST + "/api/v1/employee/auth", {
          email: user.email,
          jwt: localStorage.getItem("jwt"),
        }).then(({ data }: { data: any }) => {
          this.setState({ isAdmin: data["admin"] });
        });
      }
    });
  }
  render() {
    return (
      <Navbar className="header">
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Link to="/home" className="header-logo">
              <img src={`${process.env.PUBLIC_URL}/logo_w.png`} alt="TOP" />
            </Link>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <UserMenu
            hasJwt={
              localStorage.getItem("jwt") !== null &&
              localStorage.getItem("jwt") !== ""
            }
            isAdmin={this.state.isAdmin}
            logoutHundler={this.props.logoutHundler}
            loginHundler={this.props.loginHundler}
          ></UserMenu>
        </Navbar.Group>
      </Navbar>
    );
  }
}

export default withRouter(Header);
