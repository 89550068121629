import * as React from "react";
import { HTMLTable } from "@blueprintjs/core";
import styled from "styled-components";

import { GatHistoryResponse } from "../../repositories/types/GatRepositoryType";

export interface ReceivedHistoryTableProps {
  receivedList: GatHistoryResponse["receivedHistory"];
}
export interface ReceivedHistoryTableState {}

// 型情報取得用 Utility Type
type PickType<T, K extends keyof T> = T[K];
type HistoryItem = PickType<GatHistoryResponse["receivedHistory"], 0>;

class ReceivedHistoryTable extends React.Component<
  ReceivedHistoryTableProps,
  ReceivedHistoryTableState
> {
  render() {
    let tableRow = NotReceivedListComponent();

    // GAT受信数が1件以上の場合にのみリストを表示
    if (this.props.receivedList && this.props.receivedList.length > 0) {
      tableRow = ReceivedListComponent(this.props.receivedList);
    }

    return (
      <TableWrapper>
        <HTMLTable className="table" striped bordered>
          <thead>
            <tr>
              <td>送ってくれた人</td>
              <td>対象月</td>
              <CommentDataCell>受け取ったコメント</CommentDataCell>
            </tr>
          </thead>
          <TableBody>{tableRow}</TableBody>
        </HTMLTable>
      </TableWrapper>
    );
  }
}

export default ReceivedHistoryTable;

const NotReceivedListComponent = () => {
  return (
    <tr>
      <td colSpan={3}>これまでに受け取ったGATはまだありません</td>
    </tr>
  );
};

const ReceivedListComponent = (
  receivedList: GatHistoryResponse["receivedHistory"]
) => {
  return (
    <>
      {receivedList.map((history: HistoryItem) => {
        return (
          <tr key={history.receivedFrom + history.receivedMonth}>
            <NarrowDataCell>{history.receivedFrom}</NarrowDataCell>
            <NarrowDataCell>{history.receivedMonth}</NarrowDataCell>
            <CommentDataCell>{history.receivedComment}</CommentDataCell>
          </tr>
        );
      })}
    </>
  );
};

// ============== StyledComponents ==============
const NarrowDataCell = styled.td`
  width: 10%;
  min-width: 10rem;
  font-weight: bold;
  font-size: 1.05rem;
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    width: 5%;
    min-width: 5rem;
  }
`;

const CommentDataCell = styled.td`
  text-align: left;
  word-wrap: break-word;
`;

const TableBody = styled.tbody`
  width: 100%;
  display: table;
`;

const TableWrapper = styled.div`
  table {
    width: 100%;
    height: 200px;
    display: block;
    overflow-y: scroll;
  }
  @media screen and (max-width: 480px) {
    white-space: nowrap;
  }
`;
