import React from "react";
import { AnchorButton, Callout, H3, InputGroup } from "@blueprintjs/core";
import Axios from "axios";

interface State {
  email: string;
}

const GAT_EXCHANGE_DESIGNATION_API_URL: string =
  process.env.REACT_APP_HOST + "/api/v1/admin/employee/gats/exchange";

class UserDesignationExchange extends React.Component<{}, State> {
  async handleCSVDownload() {
    if (this.state.email === "") {
      return;
    }

    const { data } = await Axios.get(GAT_EXCHANGE_DESIGNATION_API_URL, {
      params: {
        email: this.state.email,
        admin: localStorage.getItem("admin") !== "",
      },
      headers: { "X-ZIGEXNIST-TOKEN": localStorage.getItem("email") },
    });

    const { body } = data;
    const formatedData = formatResponse(body);

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, formatedData], { type: "text/csv" });

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, "集計用 - ユーザー指定版.csv");

      // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
      window.navigator.msSaveOrOpenBlob(blob, "集計用 - ユーザー指定版.csv");
    } else {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "集計用 - ユーザー指定版.csv";
      link.click();
      link.remove();
    }
  }

  constructor(props: {}) {
    super(props);
    this.state = {
      email: "",
    };
  }

  render() {
    return (
      <>
        <H3>社員指定のGAT受信数CSVファイル</H3>
        <Callout>
          <p className="admin-card-description">
            特定の社員のQ内のGAT受信数を集計したCSVファイルです
            <br />
            社員退職時など、いつでもQ内に受信したGAT数を集計することが出来ます
          </p>
          <InputGroup
            id="email"
            type="email"
            value={this.state.email}
            required
            fill
            placeholder="対象の社員のメールアドレス"
            leftIcon="envelope"
            className="admin-csv-download-form"
            onChange={(e: any) => {
              this.setState({ email: e.target.value });
            }}
          ></InputGroup>
          <AnchorButton
            id="download"
            download="集計用 - 社員指定版.csv"
            onClick={() => this.handleCSVDownload()}
            intent="primary"
            className="admin-csv-download-button"
            icon="import"
            large
            fill
          >
            社員指定でCSVダウンロード
          </AnchorButton>
        </Callout>
      </>
    );
  }
}

function formatResponse(rawResponse: any) {
  return `社員番号,氏名,GAT数
${rawResponse.employee_num},${rawResponse.name},${rawResponse.gats}
`;
}

export default UserDesignationExchange;
