import React, { Fragment } from "react";
import { MenuItem, Classes } from "@blueprintjs/core";
import { Link } from "react-router-dom";

interface Props {
  user: any; // FIXME
  logoutHundler: any; // FIXME
  isAdmin: boolean;
}

class AuthedMenu extends React.Component<Props> {
  render() {
    return (
      <Fragment>
        <ul className={Classes.DARK + " " + Classes.LIST_UNSTYLED}>
          <MenuItem text="MENU" icon="menu">
            <li className="bp3-menu-item">
              ログイン中:{this.props.user && this.props.user.displayName}
            </li>
            <Link to="/home" className="bp3-menu-item bp3-icon-home">
              HOMEに戻る
            </Link>
            <Link to="/inquiry" className="bp3-menu-item bp3-icon-form">
              ご意見・要望フォーム
            </Link>
            <AdminMenu isAdmin={this.props.isAdmin}></AdminMenu>
            <li className="bp3-menu-divider"></li>
            <li onClick={this.props.logoutHundler}>
              <span className="bp3-menu-item bp3-icon-log-out bp3-intent-danger">
                ログアウト
              </span>
            </li>
          </MenuItem>
        </ul>
      </Fragment>
    );
  }
}

class AdminMenu extends React.Component<{ isAdmin: boolean }> {
  render() {
    if (this.props.isAdmin) {
      return (
        <Link to="/admin" className="bp3-menu-item bp3-icon-cog">
          GAT管理
        </Link>
      );
    } else {
      return "";
    }
  }
}

export default AuthedMenu;
