import React from "react";
import { AnchorButton, Callout, H3 } from "@blueprintjs/core";

class GatAwardButton extends React.Component {
  GAT_AWARD_API_URL: string =
    process.env.REACT_APP_HOST + "/api/v1/admin/gats/award";
  render() {
    return (
      <>
        <H3 className="admin-csv-download-title">
          ZEUS 348°感謝賞 表彰用CSVファイル
        </H3>
        <Callout>
          <p className="admin-card-description">
            ZEUS 348°感謝賞の受賞者を確認するためのCSVファイルです
            <br />
            Q内のGAT受信数上位10名の名前と受信枚数（順位）が確認できます
            <br />
            ※集計対象月（1月、4月、7月、10月）以外ではダウンロードは出来ません
          </p>
          <AnchorButton
            intent="primary"
            large
            className="admin-csv-download-button"
            icon="import"
            text="CSVダウンロード"
            href={this.GAT_AWARD_API_URL}
          />
        </Callout>
      </>
    );
  }
}

export default GatAwardButton;
