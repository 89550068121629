import * as React from "react";
import { Card, HTMLTable, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "styled-components";

import { GatStatusResponse } from "../../repositories/types/GatRepositoryType";

export interface StatusTableProps {
  status: GatStatusResponse;
}

class StatusTable extends React.Component<StatusTableProps> {
  render() {
    return (
      <Card>
        <Title>
          <IconWrapper>
            <Icon icon={IconNames.SAVED} iconSize={Icon.SIZE_LARGE} />
          </IconWrapper>
          これまで受け取ったGAT
        </Title>
        <TableWrapper>
          <HTMLTable bordered condensed>
            <thead>
              <tr>
                <th>カテゴリー</th>
                <th>GAT数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>次回換金予定数</td>
                <td>{this.props.status.redeemGats}</td>
              </tr>
              <tr>
                <td>年度内獲得数</td>
                <td>{this.props.status.thisYearGats}</td>
              </tr>
              <tr>
                <td>入社からの累計獲得数</td>
                <td>{this.props.status.totalGats}</td>
              </tr>
            </tbody>
          </HTMLTable>
        </TableWrapper>
      </Card>
    );
  }
}

export default StatusTable;

// ============== StyledComponents ==============
const Title = styled.h2`
  margin: 0 auto 1rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ffffff;
  font-size: 1.3rem;
  text-align: left;
`;

const IconWrapper = styled.i`
  padding: 0 0.3rem;
  font-weight: normal;
`;

const TableWrapper = styled.div`
  width: 25vw;
  table {
    width: 100%;
  }

  th,
  td {
    font-size: 1.1rem;
    text-align: center !important;
  }

  th {
    background: rgba(255, 255, 255, 0.1);
    font-size: 90%;
  }

  td:first-child {
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
