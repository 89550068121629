import React from "react";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Classes,
  Card,
  H2,
  AnchorButton,
  Callout,
  Icon,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import GatExchangeButton from "../../component/admin/gatExchangeButton";
import GatAwardButton from "../../component/admin/gatAwardButton";
import UserDesignationExchange from "../../component/admin/gatUserDesignationExchange";
import ErrorLoggingTable from "../../component/admin/ErrorLoggingTable";
import UserListPageLink from "../../component/admin/UserListPageLink";

import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import { ZigexnistError } from "../../repositories/firebase/ErrorLoggingRepository";
const ErrorLoggingRepository = RepositoryFactory.get("errorLogging");

interface Props extends RouteComponentProps {}
interface State {
  errorHistory: ZigexnistError[];
}

class Admin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorHistory: [] };
  }

  async componentDidMount() {
    const errorHistory = await ErrorLoggingRepository.get();

    this.setState({ errorHistory: errorHistory });
  }

  render() {
    const isAdmin = localStorage.getItem("admin");
    return isAdmin ? (
      <section className={Classes.DARK + " admin"}>
        <Card className="admin-card two-thirds">
          <H2>
            <Icon
              className="home-title-icon"
              icon={IconNames.IMPORT}
              iconSize={Icon.SIZE_LARGE}
            />
            CSVファイルを一括ダウンロード
          </H2>
          <GatExchangeButton></GatExchangeButton>
          <GatAwardButton></GatAwardButton>
        </Card>
        <Card className="admin-card one-third">
          <H2>
            <Icon
              className="home-title-icon"
              icon={IconNames.BOX}
              iconSize={Icon.SIZE_LARGE}
            />
            CSVファイルを個別でダウンロード
          </H2>
          <UserDesignationExchange></UserDesignationExchange>
        </Card>
        <Card className="admin-card full">
          <H2>
            <Icon
              className="home-title-icon"
              icon={IconNames.CHART}
              iconSize={Icon.SIZE_LARGE}
            />
            週ごとのGAT送受信数集計
          </H2>
          <Callout>
            <p className="admin-card-description">
              GATの全体の送受信数を確認できるページです
              <br />
              社内向け施策の効果検証やGATの送受信の傾向を確認できます
            </p>
            <AnchorButton
              intent="primary"
              className="admin-analytics-page-link"
              rightIcon="chevron-right"
              text="GAT集計グラフを見る"
              onClick={() => this.props.history.push("/aggregate")}
              large
              outlined
              minimal
            />
          </Callout>
        </Card>
        <UserListPageLink></UserListPageLink>
        <ErrorLoggingTable
          errorHistory={this.state.errorHistory}
        ></ErrorLoggingTable>
      </section>
    ) : (
      <Redirect to="/home" />
    );
  }
}

export default withRouter(Admin);
