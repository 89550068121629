import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, H2, Icon, Callout, AnchorButton } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

interface Props extends RouteComponentProps {}

class UserListPageLink extends React.Component<Props> {
  render() {
    return (
      <Card className="admin-card full">
        <H2>
          <Icon
            className="admin-title-icon"
            icon={IconNames.PEOPLE}
            iconSize={Icon.SIZE_LARGE}
          />
          従業員一覧
        </H2>
        <Callout>
          <p className="admin-card-description">
            ZIGExNISTに登録された従業員リストを確認ができます
            <br />
            管理者権限が付与されているか。いつデータがアップデートされたかを確認することができます。
          </p>
          <AnchorButton
            intent="primary"
            className="admin-user-list-page-link"
            rightIcon="chevron-right"
            text="登録済み従業員一覧を確認する"
            onClick={() => this.props.history.push("/admin/employees")}
            large
            outlined
            minimal
          />
        </Callout>
      </Card>
    );
  }
}

export default withRouter(UserListPageLink);
