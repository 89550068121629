import * as React from "react";
import { Callout, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "styled-components";
import RandomLeadMessage from "./RandomLeadMessage";

export interface MainMessageProps {}

export interface MainMessageState {}

class MainMessage extends React.Component<MainMessageProps, MainMessageState> {
  render() {
    return (
      <Wrapper>
        <Title>
          <IconWrapper>
            <Icon icon={IconNames.ENVELOPE} iconSize={Icon.SIZE_LARGE} />
          </IconWrapper>
          GATを送信
        </Title>
        <RandomLeadMessage></RandomLeadMessage>
        <Callout>
          <MessageContent>
            ありGAT！（ありがとう！）の気持ちから生まれた、社内通貨
            <b>「GAT」</b>を使って、
            <br />
            上司や同僚に日頃の感謝をメッセージに乗せて送ったり、
            <br />
            ドアを開けておいてくれたあの人に後からこっそりお礼を伝えたり、
            おめでたいことがあった時に送ってみたり…
            <br />
            自分が嬉しくなった気持ちを社内の誰かにおすそ分けしましょう♪
          </MessageContent>
        </Callout>
      </Wrapper>
    );
  }
}

export default MainMessage;

// ============== StyledComponents ==============
const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  margin: 0 auto 1rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ffffff;
  font-size: 1.3rem;
  text-align: left;
`;

const MessageContent = styled.p`
  margin: 0;
  padding: 0.5rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.8rem;
`;

const IconWrapper = styled.i`
  padding: 0 0.3rem;
  font-weight: normal;
`;
