import { EmployeeForAdmin } from "../../page/admin/employees";
import { Employee } from "../../page/send/send";
import { FirebaseTimestamp, Repository } from "../BaseRepository";

import ErrorLoggingRepository, {
  ZigexnistError,
} from "../firebase/ErrorLoggingRepository";

type ResponseAny = any;

export default {
  async getAllEmployeeName(
    userEmail: string | null
  ): Promise<Employee[] | null> {
    try {
      const { data, status } = await Repository.get("/employees", {
        headers: { "X-ZIGEXNIST-TOKEN": userEmail },
      });

      if (status && status !== 200) {
        throw new Error("ZGI_0001: API Access Error.");
      }

      const rawEmployeeList: Employee[] = data.employee_datas.map(
        (e: ResponseAny): Employee => {
          return {
            value: e.employee_id,
            label: e.name,
            ruby: e.ruby,
          };
        }
      );

      const employeeList = rawEmployeeList.sort((a, b) => {
        return a.ruby > b.ruby ? 1 : -1;
      });

      return employeeList;
    } catch (e) {
      console.error(e);
      // TODO: エラーメッセージを画面上に表示

      ErrorLoggingRepository.postError({
        message: e.message,
        location: window.location.href,
        userId: userEmail,
        timestamp: FirebaseTimestamp.now(),
      } as ZigexnistError);

      return null;
    }
  },

  async getEmployeeListForAdmin(
    userEmail: string | null
  ): Promise<EmployeeForAdmin[] | null> {
    try {
      const { data } = await Repository.get("/admin/employees/registered", {
        headers: { "X-ZIGEXNIST-TOKEN": userEmail },
      });

      const rawEmployeeList: EmployeeForAdmin[] = data.employees.map(
        (e: ResponseAny): EmployeeForAdmin => {
          return {
            name: e.name,
            nickname: e.nickname,
            ruby: e.ruby,
            employeeNum: e.employeeNum,
            email: e.email,
            isDeactivated: e.isDeactivated,
            isAdmin: e.isAdmin,
            isLoginBefore: e.isLoginBefore,
            createdAt: e.createdAt,
            updatedAt: e.updatedAt,
          };
        }
      );

      const employeeList = rawEmployeeList.sort((a, b) => {
        return a.employeeNum > b.employeeNum ? 1 : -1;
      });

      return employeeList;
    } catch (e) {
      console.error(e);
      // TODO: エラーメッセージを画面上に表示

      ErrorLoggingRepository.postError({
        message: e.message,
        location: window.location.href,
        userId: userEmail,
        timestamp: FirebaseTimestamp.now(),
      } as ZigexnistError);

      return null;
    }
  },
};
