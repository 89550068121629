import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from "./component/common/header";
import Auth from "./component/common/auth";
import GatAggregate from "./component/admin/gatAggregate";

import Login from "./page/login/login";
import Home from "./page/home/home";
import Admin from "./page/admin/admin";
import Send from "./page/send/send";
import Inquiry from "./page/inquiry/inquiry";
import NotFound from "./page/notFound/notFound";
import Employees from "./page/admin/employees";

import { HomePage } from "./page/new/HomePage";

import "./assets/scss/App.scss";
import { login, logout } from "./firebase";

class App extends React.Component {
  // ここにstateとonAuthStateChangedを作る
  render() {
    return (
      <BrowserRouter>
        <Header loginHundler={login} logoutHundler={logout}></Header>
        <Switch>
          <Route exact path="/new/home" component={HomePage} />
          <Route
            exact
            path="/"
            render={() => (
              <Login
                loginHundler={login}
                isLoginProcessing={
                  localStorage.getItem("loginProcessing") !== null
                }
              />
            )}
          />
          <Auth>
            <Switch>
              <Route exact path="/home" component={Home} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/admin/employees" component={Employees} />
              <Route exact path="/aggregate" component={GatAggregate} />
              <Route exact path="/send" component={Send} />
              <Route exact path="/inquiry" component={Inquiry} />
              <Route exact>
                <NotFound />
              </Route>
            </Switch>
          </Auth>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
