import IGatRepository from "../types/GatRepositoryType";

const MGatRepository: IGatRepository = {
  // 送受信履歴取得
  async getHistory(
    userToken,
    option = { isSuccess: true, hasSentReceived: true }
  ) {
    console.log("[TOKEN]:", userToken);

    return new Promise((resolve, reject) => {
      if (option.isSuccess && !option.hasSentReceived) {
        resolve({
          sendHistory: [],
          receivedHistory: [],
        });
      } else if (option.isSuccess && option.hasSentReceived) {
        resolve({
          sendHistory: [
            {
              sendComment: "いつもありGATございます！",
              sendTo: "送信 済男",
              sendMonth: "2020年3月分",
            },
            {
              sendComment:
                "ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！",
              sendTo: "送信 おくり子",
              sendMonth: "2020年8月分",
            },
            {
              sendComment: `ありGATございました！


                ありGATございました！`,
              sendTo: "送信 志多男",
              sendMonth: "2020年10月分",
            },
          ],
          receivedHistory: [
            {
              receivedComment: "ありGATございました！",
              receivedFrom: "受信 出来男",
              receivedMonth: "2020年4月分",
            },
            {
              receivedComment: `ありGATございました！

              ありGATございました！`,
              receivedFrom: "受信 志多美",
              receivedMonth: "2020年8月分",
            },
            {
              receivedComment:
                "ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！ありGATございました！",
              receivedFrom: "受信 やる男",
              receivedMonth: "2020年11月分",
            },
          ],
        });
      } else {
        reject(new Error("Something Error."));
      }
    });
  },

  // 受け取り総数取得
  async getStatus(userToken, option = { isSuccess: true }) {
    console.log("[TOKEN]:", userToken);

    return new Promise((resolve, reject) => {
      if (option.isSuccess) {
        resolve({
          redeemGats: 0,
          thisYearGats: 2,
          totalGats: 4,
        });
      } else {
        reject(new Error("Something Error."));
      }
    });
  },

  // 保持情報取得
  async getPossession(
    userToken,
    option = { isSuccess: true, alreadySend: true }
  ) {
    console.log("[TOKEN]:", userToken);

    return new Promise((resolve, reject) => {
      if (option.isSuccess && option.alreadySend) {
        resolve({
          hasGat: false,
          sentUser: "送信　済男",
        });
      } else if (option.isSuccess && !option.alreadySend) {
        resolve({
          hasGat: true,
          sentUser: "",
        });
      } else {
        reject(new Error("Something Error."));
      }
    });
  },

  // 送信実行
  async post(userToken, payload, option = { isSuccess: true }) {
    console.log("[TOKEN]:", userToken);
    console.log("[PAYLOAD]:", payload);

    return new Promise((resolve, reject) => {
      if (option.isSuccess) {
        resolve(true);
      } else {
        reject(new Error("Something Error."));
      }
    });
  },
};

export default MGatRepository;
