import React from "react";
import { Tabs, Tab, Card, Icon } from "@blueprintjs/core";
import GatHistoryOfSend, { SendHistory } from "./gatHistoryOfSend";
import GatHistoryOfReceived, { ReceivedHistory } from "./gatHistoryOfReceived";
import { IconNames } from "@blueprintjs/icons";

import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const GatHistoryRepository = RepositoryFactory.get("gatHistory");

interface Props {}
interface State {
  gatReceivedHistory?: Array<ReceivedHistory> | null;
  gatSendHistory?: Array<SendHistory> | null;
  selectedTabId: string;
}

class GatHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTabId: "gat-received",
    };
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    const { sendHistory, receivedHistory } = await GatHistoryRepository.get(
      userEmail
    );

    this.setState({
      gatSendHistory: sendHistory,
      gatReceivedHistory: receivedHistory,
    });
  }

  changeSelectedTab(_selectedTabId: string) {
    this.setState({ selectedTabId: _selectedTabId });
  }

  render() {
    return (
      <section className="home-gat-history-wrapper">
        <Card>
          <h2 className="home-main-title">
            <Icon
              className="home-title-icon"
              icon={IconNames.CHAT}
              iconSize={Icon.SIZE_LARGE}
            />
            GAT送受信履歴
          </h2>
          <Tabs
            id="gat-history"
            defaultSelectedTabId="gat-received"
            onChange={this.changeSelectedTab.bind(this)}
            selectedTabId={this.state.selectedTabId}
            large
          >
            <Tab
              id="gat-received"
              panel={
                <GatHistoryOfReceived
                  gat={
                    this.state.gatReceivedHistory
                      ? this.state.gatReceivedHistory.reverse()
                      : null
                  }
                />
              }
              title="受信履歴"
            ></Tab>
            <Tab
              id="gat-send"
              panel={
                <GatHistoryOfSend
                  gat={
                    this.state.gatSendHistory
                      ? this.state.gatSendHistory.reverse()
                      : null
                  }
                />
              }
              title="送信履歴"
            ></Tab>
          </Tabs>
        </Card>
      </section>
    );
  }
}

export default GatHistory;
