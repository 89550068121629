// Repository Types
import { Repository, FirebaseTimestamp } from "../BaseRepository";
import ErrorLoggingRepository, {
  ZigexnistError,
} from "../firebase/ErrorLoggingRepository";
import IGatRepository, {
  GatHistoryResponse,
  GatPossessionResponse,
  GatStatusResponse,
  SendRequestPayload,
} from "../types/GatRepositoryType";

type RESPONSE_any = any; // レスポンスから返ってきた状態の any 型。メソッド内部で型情報付与処理が必要

// 型情報取得用 Utility Type
type PickType<T, K extends keyof T> = T[K];
type SendHistoryItem = PickType<GatHistoryResponse["sendHistory"], 0>;
type ReceivedHistoryItem = PickType<GatHistoryResponse["receivedHistory"], 0>;

const GatRepository: IGatRepository = {
  async getHistory(userToken: string) {
    let sender: RESPONSE_any, receiver: RESPONSE_any;

    try {
      const { data } = await Repository.get("/employee/gats/history", {
        headers: { "X-ZIGEXNIST-TOKEN": userToken },
      });

      sender = data.sender;
      receiver = data.receiver;
    } catch (e) {
      handlingError(e, userToken);

      return {
        sendHistory: [],
        receivedHistory: [],
      };
    }

    // APIレスポンスの整形
    let response: GatHistoryResponse = {
      sendHistory: [],
      receivedHistory: [],
    };

    response.sendHistory = sender.map(
      (sender: RESPONSE_any): SendHistoryItem => {
        const senderData: SendHistoryItem = {
          sendComment: sender.comment,
          sendTo: sender.name,
          sendMonth: sender.date,
        };
        return senderData;
      }
    );

    response.receivedHistory = receiver.map((receiver: RESPONSE_any) => {
      const receiverData: ReceivedHistoryItem = {
        receivedComment: receiver.comment,
        receivedFrom: receiver.name,
        receivedMonth: receiver.date,
      };
      return receiverData;
    });

    return response;
  },
  async getPossession(userToken: string) {
    try {
      const { data }: { data: RESPONSE_any } = await Repository.get(
        "/employee/status",
        {
          headers: { "X-ZIGEXNIST-TOKEN": userToken },
        }
      );

      if (data.employee_status) {
        const possession: GatPossessionResponse = {
          sentUser: data.employee_status.send_user,
          hasGat: data.employee_status.has_gat,
        };
        return possession;
      } else {
        throw new Error("ZGE_0001: GAT送信情報が存在しませんでした");
      }
    } catch (e) {
      handlingError(e, userToken);

      return {
        hasGat: null,
        sentUser: "",
      };
    }
  },
  async getStatus(userToken: string) {
    try {
      const { data } = await Repository.get("/employee/gats", {
        headers: { "X-ZIGEXNIST-TOKEN": userToken },
      });

      if (data) {
        const realData = data.data;

        const status: GatStatusResponse = {
          redeemGats: realData.redeem_gats,
          thisYearGats: realData.this_year_gats,
          totalGats: realData.total_gats,
        };
        return status;
      } else {
        throw new Error(
          "ZGE_0002: これまでに受け取ったGAT情報が存在しませんでした"
        );
      }
    } catch (e) {
      handlingError(e, userToken);

      return { redeemGats: 0, thisYearGats: 0, totalGats: 0 };
    }
  },
  async post(userToken: string, payload: SendRequestPayload) {
    return true;
  },
};

export default GatRepository;

const handlingError = (e: any, userToken: string) => {
  console.error(e);

  // フロントエンドでのError情報をFirebaseに登録
  ErrorLoggingRepository.postError({
    message: e.message,
    location: window.location.href,
    userId: userToken,
    timestamp: FirebaseTimestamp.now(),
  } as ZigexnistError);

  //ネットワークエラーの場合
  if (!e.response) {
    alert("ネットワークエラーが発生しました。VPN接続をご確認ください");
    return;
  }

  if (e.response.status === 500) {
    alert(
      "何らかのエラーが発生しました。お手数ですが、経営推進部、もしくはZIGExNIST管理人までご連絡ください"
    );
    return;
  }
};
