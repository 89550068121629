import React from "react";
import { Classes } from "@blueprintjs/core";

import GatStatus from "../component/home/gatStatus";
import GatHistory from "../component/home/gatHistory";
import GatSend from "../component/home/gatSend";

interface Props {}
interface State {}

class Home extends React.Component<Props, State> {
  render() {
    return (
      <section className={Classes.DARK + " home"}>
        <GatSend />
        <div className="home-gat-information-wrapper">
          <GatHistory />
          <GatStatus />
        </div>
      </section>
    );
  }
}

export default Home;
