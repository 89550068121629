import React from "react";
import { Callout } from "@blueprintjs/core";
import { Link } from "react-router-dom";

interface Props {}
interface State {}

class GatNotice extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <Callout intent="warning" className="home-gat-send-button-sub-callout">
          GATは1ヶ月に1回、1人にしか送ることができません。
        </Callout>
        <Callout intent="warning" className="home-gat-send-button-sub-callout">
          「GATの送信状況を取得できません」と表示される場合はVPN接続をご確認ください
        </Callout>
        <Callout intent="primary" className="home-gat-send-button-sub-callout">
          ご意見・ご要望は<Link to="/inquiry">ご意見・ご要望フォーム</Link>から
        </Callout>
      </div>
    );
  }
}

export default GatNotice;
