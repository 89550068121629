import React from "react";
import { AnchorButton, Callout, H3 } from "@blueprintjs/core";
interface Props {}
interface State {}

class GatExchangeButton extends React.Component<Props, State> {
  GAT_EXCHANGE_API_URL: string =
    process.env.REACT_APP_HOST + "/api/v1/admin/gats/exchange";
  render() {
    return (
      <>
        <H3 className="admin-csv-download-title">
          Amazonギフトカード換金情報のCSVファイル
        </H3>
        <Callout>
          <p className="admin-card-description">
            社員ごとにGATを何枚Amazonギフトカードに換金する必要があるのかがまとまったCSVファイルです
            <br />
            ※集計対象月（1月、4月、7月、10月）以外ではダウンロードは出来ません
          </p>
          <AnchorButton
            intent="primary"
            large
            className="admin-csv-download-button"
            icon="import"
            text="CSVダウンロード"
            href={this.GAT_EXCHANGE_API_URL}
          />
        </Callout>
      </>
    );
  }
}

export default GatExchangeButton;
