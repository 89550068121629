import React, { Fragment } from "react";
import { Icon, Callout } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

interface Props {}
interface State {
  mainMessage: string;
}

const MainMessagePattern = [
  "いつも〇〇してくれて、「ありGAT！」",
  "助けてくれて、「ありGAT！」",
  "おめでとう！そして、いつも「ありGAT！」",
  "教えてくださって、「ありGAT！」ございました！",
  "気にかけてくださって、「ありGAT！」ございます！",
];

class GatSendMessage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mainMessage: MainMessagePattern[0],
    };
  }
  componentDidMount() {
    const randomNumber = Math.floor(Math.random() * 5);
    this.setState({ mainMessage: MainMessagePattern[randomNumber] });
  }

  render() {
    return (
      <Fragment>
        <h2 className="home-main-title">
          <Icon
            className="home-title-icon"
            icon={IconNames.ENVELOPE}
            iconSize={Icon.SIZE_LARGE}
          />
          GATを送信
        </h2>
        <div className="home-gat-send-button-message">
          <div>
            <h3>{this.state.mainMessage}</h3>
            <Callout>
              <p>
                ありGAT！（ありがとう！）の気持ちから生まれた、社内通貨
                <b>「GAT」</b>を使って、
                <br />
                上司や同僚に日頃の感謝をメッセージに乗せて送ったり、
                <br />
                ドアを開けておいてくれたあの人に後からこっそりお礼を伝えたり、
                おめでたいことがあった時に送ってみたり…
                <br />
                自分が嬉しくなった気持ちを社内の誰かにおすそ分けしましょう♪
              </p>
            </Callout>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GatSendMessage;
