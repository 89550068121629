import React from "react";
import { HTMLTable } from "@blueprintjs/core";
import { Link } from "react-router-dom";

interface Props {
  gat: Array<SendHistory> | null;
}
interface State {}

export interface SendHistory {
  sendTo: string;
  sendComment: string;
  sendMonth: string;
}

class GatHistoryOfSend extends React.Component<Props, State> {
  render() {
    const gatCount = this.props.gat ? this.props.gat.length : 0;

    return (
      <article>
        <HTMLTable className="home-gat-history-table" striped bordered>
          <thead>
            <tr>
              <th className="home-gat-history-user">送った相手</th>
              <th className="home-gat-history-month">対象月</th>
              <th className="home-gat-history-comment">あなたのコメント</th>
            </tr>
          </thead>
          <tbody>
            {/* 送信したGATがない場合に表示 */}
            {gatCount <= 0 && (
              <tr>
                <td colSpan={3} className="home-gat-history-empty">
                  これまでに送信したGATはまだありません。
                  <br />
                  <Link to="/send">こちら</Link>
                  から初めてのGATを送信してみましょう！
                </td>
              </tr>
            )}

            {this.props.gat &&
              this.props.gat.map((history: SendHistory) => {
                return (
                  <tr key={history.sendComment}>
                    <td className="home-gat-history-user">{history.sendTo}</td>
                    <td className="home-gat-history-month">
                      {history.sendMonth}
                    </td>
                    <td className="home-gat-history-comment">
                      {history.sendComment}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </HTMLTable>
      </article>
    );
  }
}

export default GatHistoryOfSend;
