import React, { Fragment } from "react";
import { Button, Classes } from "@blueprintjs/core";

interface Props {
  loginHundler: any; //FIXME
}

class UnauthedMenu extends React.Component<Props> {
  render() {
    return (
      <Fragment>
        <Button
          minimal={true}
          icon="log-in"
          large={true}
          onClick={this.props.loginHundler}
        >
          <span className={Classes.DARK}>LOGIN</span>
        </Button>
      </Fragment>
    );
  }
}

export default UnauthedMenu;
