import React from "react";
import InquiryUI from "../component/inquiry/inquiry";

import { Feature } from "../../repositories/firebase/FeaturesRepository";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const FeaturesRepository = RepositoryFactory.get("featuresRepository");

interface State {
  features: Feature[];
}

class Inquiry extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      features: [],
    };
  }

  async componentDidMount() {
    const features: Feature[] = await FeaturesRepository.get();

    this.setState({ features });
  }

  render() {
    return <InquiryUI features={this.state.features}></InquiryUI>;
  }
}

export default Inquiry;
