import { FirebaseRepository } from "../BaseRepository";

export interface Feature {
  title: string;
  about: string;
  priority: string;
}

export default {
  get(): Promise<Feature[]> {
    return FirebaseRepository.collection("additional-features")
      .orderBy("sort")
      .get()
      .then((quest) => {
        return quest.docs.map((doc) => {
          const { title, about, priority } = doc.data();
          return { title, about, priority } as Feature;
        });
      });
  },
};
