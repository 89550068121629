import { FirebaseTimestamp, Repository } from "../BaseRepository";

import ErrorLoggingRepository, {
  ZigexnistError,
} from "../firebase/ErrorLoggingRepository";

interface RequestBody {
  email: string;
  selectetId: string;
  comment: string;
}

export default {
  async post(
    userEmail: string | null,
    requestBody: RequestBody
  ): Promise<Boolean> {
    try {
      const { status } = await Repository.post(
        "/employee/gats/send",
        {
          email: requestBody.email,
          employee_id: requestBody.selectetId,
          comment: requestBody.comment,
        },
        {
          headers: { "X-ZIGEXNIST-TOKEN": requestBody.email },
        }
      );

      if (status && status !== 200) {
        throw new Error("ZGI_0001: API Access Error.");
      }

      return true;
    } catch (e) {
      console.error(e);

      alert(
        "GAT送信失敗しました。再度送信してください。何度も失敗する場合はZIGExNIST管理者もしくは経営推進部宛にご連絡ください"
      );

      ErrorLoggingRepository.postError({
        message: e.message,
        location: window.location.href,
        userId: userEmail,
        timestamp: FirebaseTimestamp.now(),
      } as ZigexnistError);

      return false;
    }
  },
};
