import React from "react";

import firebase from "../../../src/firebase";
import { User } from "firebase";
import { Classes, Card, Spinner } from "@blueprintjs/core";

import LoginButon from "../../assets/img/btn_google_signin_light.png";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {
  loginHundler: any; // FIXME
  isLoginProcessing: boolean;
}
interface State {
  user: User | null;
}
class LoginPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: firebase.auth().currentUser,
    };
  }

  componentDidMount() {
    const urlParam = this.props.location.search.substring(1);
    const paramValue: any = urlParam.split("=");
    let timer: any;
    /* 401エラー処理 */
    if (paramValue[0] === "error" && paramValue[1] === 1) {
      alert("ログインの認証が失敗しました");
    }

    if (
      localStorage.getItem("loginProcessing") &&
      localStorage.getItem("loginProcessing") !== ""
    ) {
      timer = setTimeout(() => {
        localStorage.removeItem("loginProcessing");
        alert("ログインに失敗しました。再度ログインをお試しください");
        this.props.history.push("/");
      }, 15000);
    }
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ user });
        const jwt = this.state.user ? await this.state.user.getIdToken() : "";
        localStorage.setItem("jwt", jwt);
        const email = user.email || "";
        localStorage.setItem("email", email);

        if (localStorage.getItem("jwt") !== "") {
          clearTimeout(timer);
          localStorage.removeItem("loginProcessing");
          this.props.history.push("/home");
        }
      }
    });
  }
  //componentDidMountはrenderが実行された後に行われる。データの受け渡しが可能な状態になったら下記のコードが実行されていく。
  //onAuthstateChangeでuserにログインしたユーザーの情報を与える

  render() {
    if (this.props.isLoginProcessing) {
      return (
        <section className={Classes.DARK + " login-processing"}>
          <p>ログイン中…</p>
          <Spinner intent="primary"></Spinner>
        </section>
      );
    } else {
      return (
        <section className={Classes.DARK}>
          <figure className="login-logo">
            <img src={`${process.env.PUBLIC_URL}/logo_w.png`} alt="zigexnist" />
          </figure>
          <Card className="login-button-area">
            <h1>ようこそ、ZIGExNISTへ</h1>
            <p>
              <ruby>
                <b>ZIGExNIST</b>
                <rt>じげにすと</rt>
              </ruby>
              は<br />
              <b>じげん社員専用Webサービス</b>で<br />
              福利厚生の一つ、社内通貨GATの送受信などを行うことが出来ます
            </p>
            <p>
              ご自身がお持ちのじげん
              <br />
              もしくは関連子会社のGoogleアカウントでログインし、
              <br />
              さっそくZIGExNISTの世界に飛び込みましょう
            </p>
            <div className="button-wrapper" onClick={this.props.loginHundler}>
              <img src={LoginButon} alt="Login Button"></img>
            </div>
          </Card>
        </section>
      );
    }
  }
}

export default withRouter(LoginPage);
