import React from "react";
import { HTMLTable } from "@blueprintjs/core";

interface Props {
  gat: Array<ReceivedHistory> | null;
}
interface State {}

export interface ReceivedHistory {
  receivedFrom: string;
  receivedComment: string;
  receivedMonth: string;
}

class GatHistoryOfReceived extends React.Component<Props, State> {
  render() {
    const gatCount = this.props.gat ? this.props.gat.length : 0;
    return (
      <article>
        <HTMLTable className="home-gat-history-table" striped bordered>
          <thead>
            <tr>
              <th className="home-gat-history-user">送ってくれた人</th>
              <th className="home-gat-history-month">対象月</th>
              <th className="home-gat-history-comment">受け取ったコメント</th>
            </tr>
          </thead>
          <tbody>
            {/* 受け取ったGATがない場合に表示 */}
            {gatCount <= 0 && (
              <tr>
                <td colSpan={3} className="home-gat-history-empty">
                  これまでに受け取ったGATはまだありません
                </td>
              </tr>
            )}

            {this.props.gat &&
              this.props.gat.map((history: ReceivedHistory) => {
                return (
                  <tr key={history.receivedFrom}>
                    <td className="home-gat-history-user">
                      {history.receivedFrom}
                    </td>
                    <td className="home-gat-history-month">
                      {history.receivedMonth}
                    </td>
                    <td className="home-gat-history-comment">
                      {history.receivedComment}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </HTMLTable>
      </article>
    );
  }
}

export default GatHistoryOfReceived;
