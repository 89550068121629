import React from "react";

import GatSendButtons from "./gatSendButtons";
import GatSendMessage from "./gatSendMessage";
import GatNotice from "./gatNotice";

import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const GatPossessionRepository = RepositoryFactory.get("gatPossession");

interface Props {}
interface State {
  hasGat: Boolean | null;
  sentUser: String | null;
}

class GatSend extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasGat: null, sentUser: null };
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    const possesionStatus = await GatPossessionRepository.get(userEmail);
    if (!possesionStatus) return;

    /*true/falseをエンコードしてからストレージに保存する */
    if (possesionStatus.has_gat) {
      localStorage.setItem("hasGat", window.btoa("true"));
    } else {
      localStorage.setItem("hasGat", window.btoa("false"));
    }

    this.setState({
      hasGat: possesionStatus.has_gat,
      sentUser: possesionStatus.send_user,
    });
  }

  render() {
    return (
      <section className="home-gat-send-button-wrapper">
        <div className="home-gat-send-button-main">
          <GatSendMessage></GatSendMessage>
          <GatSendButtons
            hasGat={this.state.hasGat}
            sentUser={this.state.sentUser}
          ></GatSendButtons>
        </div>
        <div className="home-gat-send-button-sub">
          <GatNotice></GatNotice>
        </div>
      </section>
    );
  }
}

export default GatSend;
