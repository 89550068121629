import * as React from "react";
import { Card, Icon, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "styled-components";

import { GatHistoryResponse } from "../../repositories/types/GatRepositoryType";
import ReceivedHistoryTable from "./ReceivedHistoryTable";
import SentHistoryTable from "./SentHistoryTable";

export interface HistoryTableProps {
  history: GatHistoryResponse;
}

export interface HistoryTableState {}

class HistoryTable extends React.Component<
  HistoryTableProps,
  HistoryTableState
> {
  render() {
    return (
      <Card>
        <Title>
          <IconWrapper>
            <Icon icon={IconNames.CHAT} iconSize={Icon.SIZE_LARGE} />
          </IconWrapper>
          GAT送受信履歴
        </Title>

        <Tabs id="gat-history" defaultSelectedTabId="gat-received" large>
          <Tab
            id="gat-received"
            title="受信履歴"
            panel={
              <ReceivedHistoryTable
                receivedList={this.props.history.receivedHistory}
              />
            }
          ></Tab>
          <Tab
            id="gat-send"
            title="送信履歴"
            panel={
              <SentHistoryTable sentList={this.props.history.sendHistory} />
            }
          ></Tab>
        </Tabs>
      </Card>
    );
  }
}

export default HistoryTable;

// ============== StyledComponents ==============
const Title = styled.h2`
  margin: 0 auto 1rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid #ffffff;
  font-size: 1.3rem;
  text-align: left;
`;

const IconWrapper = styled.i`
  padding: 0 0.3rem;
  font-weight: normal;
`;
