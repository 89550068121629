import React, { Component } from "react";
import Select from "react-select";
import { Colors } from "@blueprintjs/core";
import { Employee } from "../../send/send";

interface Props {
  selectionHundler: any;
  isUserListLoaded: boolean;
  userList: Array<Employee>;
}

class SelectUser extends Component<Props> {
  render() {
    return (
      <Select
        className="sending-select-user"
        onChange={this.props.selectionHundler}
        options={this.props.userList}
        styles={colorStyles}
        name="user-name"
        isLoading={this.props.isUserListLoaded}
        isSearchable
        isClearable
        filterOption={(option: any, rawInput: string) => {
          /*カタカナで前方一致の場合オプションに表示させる */
          if (
            option.data.ruby.indexOf(rawInput) === 0 ||
            option.data.label.indexOf(rawInput) === 0
          ) {
            return true;
          } else {
            return false;
          }
        }}
      ></Select>
    );
  }
}

export default SelectUser;

// Select Style
const colorStyles = {
  singleValue: (provided: any) => ({
    ...provided,
    color: Colors.WHITE,
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.DARK_GRAY3,
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.DARK_GRAY3,
  }),
  input: (provided: any) => ({
    ...provided,
    color: Colors.WHITE,
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.DARK_GRAY3,
    color: Colors.WHITE,
    ":active": {
      ...provided[":active"],
      backgroundColor: Colors.DARK_GRAY5,
    },
    ":hover": {
      ...provided[":hover"],
      backgroundColor: Colors.DARK_GRAY5,
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    ":hover": {
      ...provided[":hover"],
      color: Colors.LIGHT_GRAY2,
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    ":hover": {
      ...provided[":hover"],
      color: Colors.LIGHT_GRAY2,
    },
  }),
};
