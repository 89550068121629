// RepositoryFactoryパターンについて: https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
// e.g. import CitiesRepository from './v1/CitiesRepository'
import GatStatusRepository from "./v1/GatStatusRepository";
import GatHistoryRepository from "./v1/GatHistoryRepository";
import GatPossessionRepository from "./v1/GatPossessionRepository";
import EmployeeRepository from "./v1/EmployeeRepository";
import GatSendRepository from "./v1/GatSendRepository";

import FeaturesRepository from "./firebase/FeaturesRepository";
import ErrorLoggingRepository from "./firebase/ErrorLoggingRepository";

import MGatRepository from "./mock/GatRepository";
import GatRepository from "./v1/GatRepository";

interface IRepsotory {
  [key: string]: any;
}

const Repositories: IRepsotory = {
  /** ここにリポジトリ（アクセス先のAPIをラップしたオブジェクト）を設定する
   *  e.g. cities: CitiesRepository
   * */

  gatStatus: GatStatusRepository,
  gatHistory: GatHistoryRepository,
  gatPossession: GatPossessionRepository,
  gatSend: GatSendRepository,
  employee: EmployeeRepository,
  featuresRepository: FeaturesRepository,
  errorLogging: ErrorLoggingRepository,
};

//
const NewRepositories = {
  mockGat: MGatRepository,
  gat: GatRepository,
};

type RepositoryNames = keyof typeof Repositories;
type NewRepositoryNames = keyof typeof NewRepositories;

export const RepositoryFactory = {
  get: (name: RepositoryNames) => Repositories[name],
  newget: (
    name: NewRepositoryNames
  ): typeof NewRepositories[NewRepositoryNames] => NewRepositories[name],
};
