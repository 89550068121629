import { HTMLTable, Spinner } from "@blueprintjs/core";
import React from "react";
import { EmployeeForAdmin } from "../../page/admin/employees";

interface Props {
  employees: EmployeeForAdmin[];
}

class EmployeeListTable extends React.Component<Props> {
  render() {
    return (
      <div className="admin-employees-table-wrapper">
        <HTMLTable className="admin-employees-table" striped bordered>
          <thead>
            <tr>
              <th className="admin-employees-table-header narrow">
                従業員番号
              </th>
              <th className="admin-employees-table-header narrow">名前</th>
              <th className="admin-employees-table-header narrow">読み方</th>
              <th className="admin-employees-table-header narrow">あだ名</th>
              <th className="admin-employees-table-header wide">
                メールアドレス
              </th>
              <th className="admin-employees-table-header">ログイン履歴</th>
              <th className="admin-employees-table-header">初回登録</th>
              <th className="admin-employees-table-header">最終更新</th>
              <th className="admin-employees-table-header very-narrow">
                管理者
              </th>
              <th className="admin-employees-table-header very-narrow">
                無効化
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.employees.length <= 0 && (
              <tr className="admin-employees-table-spinner">
                <td colSpan={10}>
                  <Spinner intent="primary"></Spinner>
                </td>
              </tr>
            )}
            {this.props.employees &&
              this.props.employees.map((employee: EmployeeForAdmin) => {
                return (
                  <tr key={employee.employeeNum + "timestamp"}>
                    <td className="admin-employees-table-data">
                      {employee.employeeNum}
                    </td>
                    <td className="admin-employees-table-data">
                      {employee.name}
                    </td>
                    <td className="admin-employees-table-data">
                      {employee.ruby}
                    </td>
                    <td className="admin-employees-table-data">
                      {employee.nickname}
                    </td>
                    <td className="admin-employees-table-data wide">
                      {employee.email}
                    </td>
                    <td className="admin-employees-table-data narrow">
                      {employee.isLoginBefore ? "あり" : "なし"}
                    </td>
                    <td className="admin-employees-table-data">
                      {employee.createdAt}
                    </td>
                    <td className="admin-employees-table-data">
                      {employee.updatedAt}
                    </td>
                    <td className="admin-employees-table-data very-narrow">
                      {employee.isAdmin ? "○" : "-"}
                    </td>
                    <td className="admin-employees-table-data very-narrow">
                      {employee.isDeactivated ? "○" : "-"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}

export default EmployeeListTable;
