// node_modules
import * as React from "react";
import styled from "styled-components";
import { Classes } from "@blueprintjs/core";

// Object Types
import {
  GatHistoryResponse,
  GatPossessionResponse,
  GatStatusResponse,
} from "../../repositories/types/GatRepositoryType";

// Components
import MainMessage from "../../component/home/MainMessage";
import SendStatusButton from "../../component/home/SendStatusButton";
import HistoryTable from "../../component/home/HistoryTable";
import NoticeOfZigexnist from "../../component/home/NoticeOfZigexnist";

import { getUser } from "../../../src/firebase";

// Repository
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import StatusTable from "../../component/home/StatusTable";
const GatRepository = RepositoryFactory.newget("gat");

type HomePageState = {
  possession: GatPossessionResponse;
  history: GatHistoryResponse;
  status: GatStatusResponse;
};

export class HomePage extends React.Component<any, HomePageState> {
  // State初期値
  state = {
    possession: {
      hasGat: null,
      sentUser: "",
    },
    history: { sendHistory: [], receivedHistory: [] },
    status: {
      redeemGats: 0,
      thisYearGats: 0,
      totalGats: 0,
    },
  };

  // APIから各種データ取得
  async componentDidMount() {
    const currentUser = await getUser();
    let email = "";
    // let jwt = "";

    if (currentUser) {
      email = currentUser.email || "";
      // jwt = await currentUser.getIdToken();
    }

    const userToken = email;
    // const userToken = jwt; // TODO: Rails側の対応次第こちらに切り替え

    const possession = await GatRepository.getPossession(userToken);
    this.setState({ possession: possession });

    const gatHistory = await GatRepository.getHistory(userToken);
    this.setState({ history: gatHistory });

    const gatStatus = await GatRepository.getStatus(userToken);
    this.setState({ status: gatStatus });
  }

  render() {
    return (
      <Section className={Classes.DARK}>
        <LeftColumn>
          <div>
            <MainMessage></MainMessage>
            <SendStatusButton
              possession={this.state.possession}
            ></SendStatusButton>
            <HistoryTableWrapper>
              <HistoryTable history={this.state.history}></HistoryTable>
            </HistoryTableWrapper>
          </div>
        </LeftColumn>
        <RightColumn>
          <NoticeOfZigexnist />
          <StatusTable status={this.state.status} />
        </RightColumn>
      </Section>
    );
  }
}

// ============== StyledComponents ==============
const Section = styled.section`
  text-align: center;
  width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

const ColumnBase = styled.div`
  margin-top: 2rem;
`;

const LeftColumn = styled(ColumnBase)`
  width: 60vw;
  @media screen and (max-width: 480px) {
    width: 90vw;
  }
`;
const RightColumn = styled(ColumnBase)`
  width: 28vw;
  margin-left: 2vw;
  @media screen and (max-width: 480px) {
    width: 90vw;
    margin: 0 0 2rem;
  }
`;

const HistoryTableWrapper = styled.div`
  margin: 2rem 0;
`;
