import React from "react";
import { HTMLTable, Card, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const GatsStatusRepoistory = RepositoryFactory.get("gatStatus");

interface Props {}
interface Gats {
  redeemGats: number;
  thisYearGats: number;
  totalGats: number;
}

class GatStatus extends React.Component<Props, Gats> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redeemGats: 0,
      thisYearGats: 0,
      totalGats: 0,
    };
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    const gatsStatus = await GatsStatusRepoistory.get(userEmail);

    if (gatsStatus === null) return;

    this.setState({
      redeemGats: gatsStatus.data.redeem_gats,
      thisYearGats: gatsStatus.data.this_year_gats,
      totalGats: gatsStatus.data.total_gats,
    });
  }
  render() {
    return (
      <div className="home-gat-status-wrapper">
        <Card>
          <h2 className="home-main-title">
            <Icon
              className="home-title-icon"
              icon={IconNames.SAVED}
              iconSize={Icon.SIZE_LARGE}
            />
            これまで受け取ったGAT
          </h2>
          <HTMLTable className="home-tag-status-table" bordered condensed>
            <thead>
              <tr>
                <th>カテゴリー</th>
                <th>GAT数</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>次回換金予定数</b>
                </td>
                <td>{this.state.redeemGats}</td>
              </tr>
              <tr>
                <td>
                  <b>年度内獲得数</b>
                </td>
                <td>{this.state.thisYearGats}</td>
              </tr>
              <tr>
                <td>
                  <b>入社からの累計獲得数</b>
                </td>
                <td>{this.state.totalGats}</td>
              </tr>
            </tbody>
          </HTMLTable>
        </Card>
      </div>
    );
  }
}

export default GatStatus;
