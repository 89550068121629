import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "firebase";

import AuthedMenu from "./authedMenu";
import UnauthedMenu from "./unauthedMenu";

import firebase from "../../firebase";

interface Props extends RouteComponentProps {
  logoutHundler: any; // FIXME
  loginHundler: any; // FIXME
  hasJwt: boolean;
  isAdmin: boolean;
}
interface State {
  user: User | null;
}

class UserMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    if (this.props.hasJwt) {
      return (
        <AuthedMenu
          user={this.state.user}
          logoutHundler={this.props.logoutHundler}
          isAdmin={this.props.isAdmin}
        />
      );
    } else {
      return <UnauthedMenu loginHundler={this.props.loginHundler} />;
    }
  }
}

export default withRouter(UserMenu);
