import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HTMLTable } from "@blueprintjs/core";

import { GatHistoryResponse } from "../../repositories/types/GatRepositoryType";

export interface SentHistoryTableProps {
  sentList: GatHistoryResponse["sendHistory"];
}
export interface SentHistoryTableState {}

// 型情報取得用 Utility Type
type PickType<T, K extends keyof T> = T[K];
type HistoryItem = PickType<GatHistoryResponse["sendHistory"], 0>;

class SentHistoryTable extends React.Component<
  SentHistoryTableProps,
  SentHistoryTableState
> {
  render() {
    let tableRow = NotSentListComponent();

    // GAT受信数が1件以上の場合にのみリストを表示
    if (this.props.sentList && this.props.sentList.length > 0) {
      tableRow = SentListComponent(this.props.sentList);
    }

    return (
      <TableWrapper>
        <HTMLTable className="table" striped bordered>
          <thead>
            <tr>
              <td>送った相手</td>
              <td>対象月</td>
              <CommentDataCell>あなたのコメント</CommentDataCell>
            </tr>
          </thead>
          <TableBody>{tableRow}</TableBody>
        </HTMLTable>
      </TableWrapper>
    );
  }
}

export default SentHistoryTable;

const NotSentListComponent = () => {
  return (
    <tr>
      <td colSpan={3}>
        これまでに送信したGATはまだありません。
        <Link to="/send">こちら</Link>
        から初めてのGATを送信してみましょう！
      </td>
    </tr>
  );
};

const SentListComponent = (receivedList: GatHistoryResponse["sendHistory"]) => {
  return (
    <>
      {receivedList.map((history: HistoryItem) => {
        return (
          <tr key={history.sendTo + history.sendMonth}>
            <NarrowDataCell>{history.sendTo}</NarrowDataCell>
            <NarrowDataCell>{history.sendMonth}</NarrowDataCell>
            <CommentDataCell>{history.sendComment}</CommentDataCell>
          </tr>
        );
      })}
    </>
  );
};

// ============== StyledComponents ==============
const NarrowDataCell = styled.td`
  width: 10%;
  min-width: 10rem;
  font-weight: bold;
  font-size: 1.05rem;
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
    width: 5%;
    min-width: 5rem;
  }
`;

const CommentDataCell = styled.td`
  text-align: left;
  word-wrap: break-word;
`;

const TableBody = styled.tbody`
  width: 100%;
  display: table;
`;

const TableWrapper = styled.div`
  table {
    width: 100%;
    height: 200px;
    display: block;
    overflow-y: scroll;
  }
  @media screen and (max-width: 480px) {
    white-space: nowrap;
  }
`;
