import * as React from "react";
import styled from "styled-components";

export interface RandomLeadMessageProps {}

export interface RandomLeadMessageState {
  mainMessage: String;
}

const MainMessagePattern = [
  "いつも〇〇してくれて、「ありGAT！」",
  "助けてくれて、「ありGAT！」",
  "おめでとう！そして、いつも「ありGAT！」",
  "教えてくださって、「ありGAT！」ございました！",
  "気にかけてくださって、「ありGAT！」ございます！",
];

class RandomLeadMessage extends React.Component<
  RandomLeadMessageProps,
  RandomLeadMessageState
> {
  state = { mainMessage: "" };

  componentDidMount() {
    const randomNumber = Math.floor(Math.random() * 5);
    this.setState({ mainMessage: MainMessagePattern[randomNumber] });
  }

  render() {
    return <Message>{this.state.mainMessage}</Message>;
  }
}

export default RandomLeadMessage;

// ============== StyledComponents ==============

const Message = styled.h3`
  font-size: 1.4rem;
  margin: 0.5rem 0 1rem;
  text-align: center;
  -webkit-animation: fadeIn 1.5s ease-in 0s 1 normal;
  animation: fadeIn 1.5s ease-in 0s 1 normal;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;
