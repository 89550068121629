import React from "react";
import { Callout, H2 } from "@blueprintjs/core";

const NotFound: React.FC = () => {
  return (
    <>
      <H2 className="notFound-header">ページが見つかりません</H2>
      <Callout intent="warning" className="notFound-text">
        404 Not Found
        <br />
        アクセスされたページは存在しません
      </Callout>
    </>
  );
};

export default NotFound;
