import firebase from "firebase/app";
import "firebase/auth";

interface FirebaseConfig {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
}

let config: FirebaseConfig = {
  apiKey: "AIzaSyC0hjzlxO0i2nsJl2VBPRZHieLZkeTgPn8",
  authDomain: "zigexnist-v2-develop.firebaseapp.com",
  databaseURL: "https://zigexnist-v2-develop.firebaseio.com",
  projectId: "zigexnist-v2-develop",
  storageBucket: "zigexnist-v2-develop.appspot.com",
  messagingSenderId: "96324413845",
};

if (process.env.NODE_ENV === "production") {
  config = {
    apiKey: "AIzaSyBB3xXFC3OS9FFC2BM7gO6DoIidw9Zs0s8",
    authDomain: "zigexnist-v2-production.firebaseapp.com",
    databaseURL: "https://zigexnist-v2-production.firebaseio.com",
    projectId: "zigexnist-v2-production",
    storageBucket: "zigexnist-v2-production.appspot.com",
    messagingSenderId: "580842644429",
    appId: "1:580842644429:web:9ef3b07383f565a9bd9a84",
  };
}

firebase.initializeApp(config);

export default firebase;

export async function login() {
  localStorage.setItem("loginProcessing", "true");
  const provider = new firebase.auth.GoogleAuthProvider();
  await firebase.auth().signInWithRedirect(provider);
  const result = await firebase.auth().getRedirectResult();
  const user = result.user;

  if (user) {
    const jwt = await user.getIdToken();
    localStorage.setItem("jwt", jwt);
  }

  return user;
}

export function logout() {
  firebase.auth().signOut();
  localStorage.removeItem("jwt");
  localStorage.removeItem("hasGat");
  localStorage.removeItem("email");
  localStorage.removeItem("admin");
}

export function getUser(): Promise<firebase.User | null> {
  return new Promise((resolve) => {
    firebase.auth().onIdTokenChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
}
