import dayjs from "dayjs";
import "dayjs/locale/ja";

import { FirebaseRepository } from "../BaseRepository";

export interface ZigexnistError {
  message: string;
  location: string;
  userId?: string;
  timestamp: firebase.firestore.Timestamp | string;
}

dayjs.locale("ja");

export default {
  postError(error: ZigexnistError): Promise<boolean> {
    return FirebaseRepository.collection("error-logging")
      .add(error)
      .then(() => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  get(): Promise<ZigexnistError[]> {
    return FirebaseRepository.collection("error-logging")
      .orderBy("timestamp", "desc")
      .get()
      .then((errors) => {
        return errors.docs.map((doc) => {
          const { message, location, userId, timestamp } = doc.data();
          const convertedDate = timestamp.toDate();
          return {
            message,
            location,
            userId,
            timestamp: dayjs(convertedDate).format("YYYY/MM/DD HH:mm:ss"),
          } as ZigexnistError;
        });
      });
  },
};
