import React from "react";
import { Card, H2, Icon, Callout, HTMLTable } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { ZigexnistError } from "../../repositories/firebase/ErrorLoggingRepository";

interface Props {
  errorHistory: ZigexnistError[];
}

class ErrorLoggingtable extends React.Component<Props> {
  render() {
    return (
      <Card className="admin-card full">
        <H2>
          <Icon
            className="home-title-icon"
            icon={IconNames.ERROR}
            iconSize={Icon.SIZE_LARGE}
          />
          これまでに受け取ったエラー一覧
        </H2>
        <Callout>
          <p className="admin-card-description">
            これまでにフロントエンド側で検知したエラー一覧です
          </p>
          <div className="admin-error-table-wrapper">
            <HTMLTable className="admin-error-table" striped bordered>
              <thead>
                <tr>
                  <th className="admin-error-table-header">発生日時</th>
                  <th className="admin-error-table-header">エラーメッセージ</th>
                  <th className="admin-error-table-header">
                    エラー対象ユーザーID
                  </th>
                  <th className="admin-error-table-header">
                    エラー対象ページURL
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.errorHistory &&
                  this.props.errorHistory.map((errorDetail: ZigexnistError) => {
                    return (
                      <tr key={errorDetail.timestamp + ""}>
                        <td className="admin-error-table-data">
                          {errorDetail.timestamp}
                        </td>
                        <td className="admin-error-table-data">
                          {errorDetail.message}
                        </td>
                        <td className="admin-error-table-data">
                          {errorDetail.userId}
                        </td>
                        <td className="admin-error-table-data">
                          {errorDetail.location}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </HTMLTable>
          </div>
        </Callout>
      </Card>
    );
  }
}

export default ErrorLoggingtable;
