import React from "react";
import Axios from "axios";

import { Callout, H2, Classes, Icon, AnchorButton } from "@blueprintjs/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import { IconNames } from "@blueprintjs/icons";

interface Props extends RouteComponentProps {}
interface State {
  data: Array<any>;
  formatedData: Data[];
}

interface Data {
  weekIndex: string;
  count: number;
}

function GatLabel(props: any) {
  if (props.active && props.payload) {
    const gatCount = props.payload[0].payload.count;
    const weekIndex = props.payload[0].payload.weekIndex;
    return (
      <div className="gat-tooltip-wrap">
        <p className="gat-tooltip-label">
          {`${weekIndex}の週`}
          <br />
          {`GAT総送受信数: ${gatCount}`}
        </p>
      </div>
    );
  }
}

class GatAggregate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      formatedData: [],
    };
  }

  componentDidMount() {
    function formatedData(data: Array<any>): Data[] {
      let formatedData: Data[] = [];
      formatedData = data.map((d: any) => {
        const now = new Date();
        const year: number = now.getFullYear();
        const tmpData: Data = {
          weekIndex: getBeginningOfTheWeekInTheYear(year, d.week_index) + "",
          count: d.count,
        };
        return tmpData;
      });
      return formatedData;
    }

    Axios.get(process.env.REACT_APP_HOST + "/api/v1/admin/gats/report", {
      headers: { "X-ZIGEXNIST-TOKEN": localStorage.getItem("email") },
    }).then(({ data }: { data: any }) => {
      this.setState({
        data: data.data_list,
        formatedData: formatedData(data.data_list),
      });
    });
  }

  render() {
    const { formatedData } = this.state;
    return (
      <>
        <section className={Classes.DARK + " admin"}>
          <div className="admin-card two-thirds">
            <H2 className="">
              <Icon
                className="home-title-icon"
                icon={IconNames.CHART}
                iconSize={Icon.SIZE_LARGE}
              />
              週ごとのGAT送受信数集計
            </H2>
            <Callout className="" style={{ width: "100%", height: "500px" }}>
              <ResponsiveContainer height="100%" width="100%">
                <LineChart data={formatedData}>
                  <XAxis allowDataOverflow={true} dataKey="weekIndex" />
                  <YAxis yAxisId="left" dataKey="count">
                    <Label
                      style={{ fill: "#fff" }}
                      position="insideLeft"
                      angle={-90}
                    >
                      週ごとのGAT数
                    </Label>
                  </YAxis>
                  <Tooltip content={GatLabel} />
                  <Line
                    yAxisId="left"
                    type="linear"
                    dataKey="count"
                    stroke="#e3e3e3"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Callout>
            <Callout>
              グラフは1週間ごとの送受信数を集計し、折れ線で表示しています。
              <br />
              横軸は1週間の最初の1日目の日付が表示されています
            </Callout>
          </div>
          <div className="admin-card one-third">
            <Callout className="admin-aggregate-sub-callout">
              <p className="admin-aggregate-sub-callout-description">
                ZIGExNISTに登録されている従業員情報の確認はこちら
              </p>
              <AnchorButton
                intent="primary"
                className="admin-aggregate-sub-callout-button"
                rightIcon="chevron-right"
                text="従業員一覧を見る"
                onClick={() => this.props.history.push("/admin/employees")}
                large
                outlined
                minimal
              />
              <hr />
              <div>
                <p className="admin-aggregate-sub-callout-description">
                  管理画面TOPに戻る場合はこちら
                </p>
                <AnchorButton
                  intent="primary"
                  className="admin-aggregate-sub-callout-button"
                  rightIcon="chevron-right"
                  text="管理者用ページTOPに戻る"
                  onClick={() => this.props.history.push("/admin")}
                  large
                  outlined
                  minimal
                />
              </div>
            </Callout>
          </div>
        </section>
      </>
    );
  }
}

// 年と週番号を受けて、その週の最初の日にちを返す
function getBeginningOfTheWeekInTheYear(year: number, weekNumber: string) {
  const onejan = new Date(year, 0, 1);
  const offset: any = onejan.getDay();
  const d: any = new Date(
    onejan.setDate(onejan.getDate() + parseInt(weekNumber) * 7 - offset)
  );
  return `${d.getMonth() + 1}月${d.getDate()}日`;
}

export default withRouter(GatAggregate);
