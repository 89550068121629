import { FirebaseTimestamp, Repository, ResponseBase } from "../BaseRepository";
import ErrorLoggingRepository, {
  ZigexnistError,
} from "../firebase/ErrorLoggingRepository";

export default {
  async get(userEmail: string | null): Promise<ResponseBase | null> {
    try {
      const { data, status } = await Repository.get("/employee/status", {
        headers: { "X-ZIGEXNIST-TOKEN": userEmail },
      });

      if (status && status !== 200) {
        throw new Error("ZGI_0001: API Access Error.");
      }

      if (!data.employee_status) throw new Error("ZGI_0004: API Access Error.");

      return data.employee_status;
    } catch (e) {
      console.error(e);
      // TODO: エラーメッセージを画面上に表示

      ErrorLoggingRepository.postError({
        message: e.message,
        location: window.location.href,
        userId: userEmail,
        timestamp: FirebaseTimestamp.now(),
      } as ZigexnistError);

      return null;
    }
  },
};
