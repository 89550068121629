import React from "react";
import SelectUser from "../component/send/selectUser";
import { Button, Classes, Card, H1, TextArea } from "@blueprintjs/core";
import { RouteComponentProps } from "react-router-dom";

import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const EmployeeRepository = RepositoryFactory.get("employee");
const GatSendRepository = RepositoryFactory.get("gatSend");

interface Props extends RouteComponentProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

interface State {
  content: string;
  selectedUserName: string;
  isUserSelected: boolean;
  isUserListLoaded: boolean;
  hasContent: boolean;
  isSubmitted: boolean;
  employeeList: Array<Employee>;
  selectedUserID: number;
}

export interface Employee {
  value: number;
  label: string;
  ruby: string;
}

class Send extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      content: "",
      selectedUserName: "",
      isUserSelected: false,
      hasContent: false,
      isSubmitted: false,
      isUserListLoaded: false,
      employeeList: [],
      selectedUserID: 0,
    };

    this.contentChange = this.contentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectUser = this.selectUser.bind(this);
    const encodeStr: string | null = localStorage.getItem("hasGat");
    /*GATをすでに送信済みならhomeにリダイレクト */
    if (encodeStr) {
      if (window.atob(encodeStr) === "false") {
        this.props.history.push("/home");
      }
    }
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    const employeeList: Employee[] = await EmployeeRepository.getAllEmployeeName(
      userEmail
    );

    this.setState({ employeeList: employeeList, isUserListLoaded: true });
  }

  async handleSubmit() {
    const requestBody = {
      email: localStorage.getItem("email"),
      selectetId: this.state.selectedUserID,
      comment: this.state.content,
    };

    const postResult = await GatSendRepository.post(
      localStorage.getItem("email"),
      requestBody
    );

    if (postResult) {
      this.setState({ isSubmitted: true });
      this.props.history.push("/home");
    }
  }

  // 入力値の有無をチェックし、存在する場合にstateを設定する関数
  contentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const inputValue = e.target.value.trimStart();
    const isEmpty = inputValue === "";
    this.setState({
      content: inputValue,
      hasContent: !isEmpty,
    });
  }

  selectUser(_selected: Employee | null) {
    if (_selected && _selected.label) {
      this.setState({
        isUserSelected: true,
        selectedUserName: _selected.label,
        selectedUserID: _selected.value,
      });
    } else {
      this.setState({
        isUserSelected: false,
        selectedUserName: "",
        selectedUserID: 0,
      });
    }
  }

  render() {
    return (
      <section className={Classes.DARK + " sending"}>
        <Card className="sending-card">
          <H1>GAT送信</H1>
          <form method="post" onSubmit={this.handleSubmit}>
            <SelectUser
              selectionHundler={this.selectUser}
              isUserListLoaded={!this.state.isUserListLoaded}
              userList={this.state.employeeList}
            ></SelectUser>
            <TextArea
              required
              fill
              name="message"
              placeholder="メッセージはこちら"
              value={this.state.content}
              onChange={(e) => {
                this.contentChange(e);
              }}
            />
            <Button
              large
              fill
              className="sending-button"
              intent="primary"
              onClick={this.handleSubmit}
              disabled={!this.state.isUserSelected || !this.state.hasContent}
              loading={this.state.isSubmitted}
            >
              {this.state.isUserSelected
                ? `GATを${this.state.selectedUserName}さんに送信する`
                : "送信相手を選択してください"}
            </Button>
          </form>
        </Card>
      </section>
    );
  }
}
export default Send;
