import React from "react";
import dayjs from "dayjs";
import {
  AnchorButton,
  Callout,
  Card,
  Classes,
  H2,
  Icon,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import EmployeeListTable from "../../component/admin/EmployeeListTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
const EmployeeRepository = RepositoryFactory.get("employee");

export interface EmployeeForAdmin {
  name: string;
  nickname: string;
  ruby: string;
  employeeNum: string;
  email: string;
  isDeactivated: boolean;
  isAdmin: boolean;
  isLoginBefore: boolean;
  createdAt: string;
  updatedAt: string;
}

interface Props extends RouteComponentProps {}
interface State {
  employees: EmployeeForAdmin[];
}

class EmployeesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { employees: [] };
  }

  async componentDidMount() {
    const userEmail = localStorage.getItem("email");
    let rawEmployeeList: EmployeeForAdmin[] = await EmployeeRepository.getEmployeeListForAdmin(
      userEmail
    );

    if (!rawEmployeeList) {
      rawEmployeeList = [];
    }

    const employeeList: EmployeeForAdmin[] = rawEmployeeList.map(
      (e: EmployeeForAdmin) => {
        return {
          ...e,
          createdAt: dayjs(e.createdAt).format("YYYY年MM月DD日"),
          updatedAt: dayjs(e.updatedAt).format("YYYY年MM月DD日"),
        };
      }
    );

    this.setState({ employees: employeeList });
  }

  render() {
    return (
      <section className={Classes.DARK + " admin-employees"}>
        <Card className="admin-card two-thirds">
          <H2>
            <Icon
              className="home-title-icon"
              icon={IconNames.PEOPLE}
              iconSize={Icon.SIZE_LARGE}
            />
            登録済み従業員一覧（検索専用）
          </H2>
          <EmployeeListTable
            employees={this.state.employees}
          ></EmployeeListTable>
        </Card>
        <div>
          <Callout intent="warning" className="admin-employees-sub-callout">
            このページでは、ZIGExNIST内にインポートされた従業員情報を確認することのみ可能です
          </Callout>
          <Callout className="admin-employees-sub-callout">
            <p className="admin-employees-sub-callout-description">
              GATの全体の送受信数確認はこちら
              <br />
              社内向け施策の効果検証やGATの送受信の傾向を確認できます
            </p>
            <AnchorButton
              intent="primary"
              className="admin-employees-sub-callout-button"
              rightIcon="chevron-right"
              text="GAT集計グラフを見る"
              onClick={() => this.props.history.push("/aggregate")}
              large
              outlined
              minimal
            />
            <hr />
            <div>
              <p className="admin-employees-sub-callout-description">
                管理画面TOPに戻る場合はこちら
              </p>
              <AnchorButton
                intent="primary"
                className="admin-employees-sub-callout-button"
                rightIcon="chevron-right"
                text="管理者用ページTOPに戻る"
                onClick={() => this.props.history.push("/admin")}
                large
                outlined
                minimal
              />
            </div>
          </Callout>
        </div>
      </section>
    );
  }
}

export default withRouter(EmployeesPage);
