import { ReceivedHistory } from "../../page/component/home/gatHistoryOfReceived";
import { SendHistory } from "../../page/component/home/gatHistoryOfSend";
import { FirebaseTimestamp, Repository } from "../BaseRepository";
import ErrorLoggingRepository, {
  ZigexnistError,
} from "../firebase/ErrorLoggingRepository";

type RESPONSE_any = any;

export default {
  async get(
    userEmail: string | null
  ): Promise<{
    sendHistory: SendHistory[] | null;
    receivedHistory: ReceivedHistory[] | null;
  }> {
    let sender: RESPONSE_any, receiver: RESPONSE_any;

    try {
      const { data, status } = await Repository.get("/employee/gats/history", {
        headers: { "X-ZIGEXNIST-TOKEN": userEmail },
      });

      if (status && status !== 200) {
        throw new Error("ZGI_0001: API Access Error.");
      }

      if (!Array.isArray(data.sender))
        throw new Error("ZGI_0002: API Access Error.");
      if (!Array.isArray(data.receiver))
        throw new Error("ZGI_0003: API Access Error.");

      sender = data.sender;
      receiver = data.receiver;
    } catch (e) {
      console.error(e);
      // TODO: エラーメッセージを画面上に表示

      ErrorLoggingRepository.postError({
        message: e.message,
        location: window.location.href,
        userId: userEmail,
        timestamp: FirebaseTimestamp.now(),
      } as ZigexnistError);

      const sendHistory = null,
        receivedHistory = null;
      return { sendHistory, receivedHistory };
    }

    // APIレスポンスの整形
    const sendHistory: Array<SendHistory> = sender.map(
      (sender: RESPONSE_any) => {
        const senderData: SendHistory = {
          sendComment: sender.comment,
          sendTo: sender.name,
          sendMonth: sender.date,
        };
        return senderData;
      }
    );

    const receivedHistory: Array<ReceivedHistory> = receiver.map(
      (receiver: RESPONSE_any) => {
        const receiverData: ReceivedHistory = {
          receivedComment: receiver.comment,
          receivedFrom: receiver.name,
          receivedMonth: receiver.date,
        };
        return receiverData;
      }
    );

    return { sendHistory, receivedHistory };
  },
};
