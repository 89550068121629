// node_modules
import * as React from "react";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { AnchorButton, Callout } from "@blueprintjs/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

// Repository
import { GatPossessionResponse } from "../../repositories/types/GatRepositoryType";

interface SendStatusButtonProps extends RouteComponentProps {
  possession: GatPossessionResponse;
}

class SendStatusButton extends React.Component<SendStatusButtonProps> {
  render() {
    // デフォルト（データ取得失敗した場合など）
    let Button = (
      <Callout intent="danger">GATの送信状況を取得できません</Callout>
    );

    if (this.props.possession.hasGat) {
      // GAT送信前の場合
      Button = SendGatPageLink(this.props);
    } else if (this.props.possession.hasGat === false) {
      // GAT送信済みの場合
      Button = AlreadyGatSentMessage(this.props.possession.sentUser);
    }

    return <>{Button}</>;
  }
}

export default withRouter(SendStatusButton);

const SendGatPageLink = (props: SendStatusButtonProps) => (
  <AnchorButton
    intent="primary"
    fill
    large
    onClick={() => props.history.push("/send")}
  >
    {dayjs().format("MM月分")}のGATを送信しましょう！
  </AnchorButton>
);

const AlreadyGatSentMessage = (userName: String) => (
  <Callout intent="success">{userName}&nbsp;さんに送信済みです！</Callout>
);
// ============== StyledComponents ==============
