import * as React from "react";
import { Callout } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

class NoticeOfZigexnist extends React.Component {
  render() {
    return (
      <NoticeWrapper>
        <Callout intent="warning">
          GATは1ヶ月に1回、1人にしか送ることができません。
        </Callout>
        <Callout intent="warning">
          「GATの送信状況を取得できません」と表示される場合はVPN接続をご確認ください
        </Callout>
        <Callout intent="primary">
          ご意見・ご要望は<Link to="/inquiry">ご意見・ご要望フォーム</Link>から
        </Callout>
      </NoticeWrapper>
    );
  }
}

export default NoticeOfZigexnist;

// ============== StyledComponents ==============
const NoticeWrapper = styled.div`
  margin: 4rem auto;
  div {
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 480px) {
    margin: 0 auto 2rem;
  }
`;
