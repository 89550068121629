import React from "react";
import Axios from "axios";
import { logout } from "../../../src/firebase";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";

interface Props extends RouteComponentProps {}

class Auth extends React.Component<Props> {
  componentDidMount() {
    //firebase.auth().onAuthStateChanged(async user => {
    if (localStorage.getItem("email")) {
      Axios.post(process.env.REACT_APP_HOST + "/api/v1/employee/auth", {
        email: localStorage.getItem("email"),
        jwt: localStorage.getItem("jwt"),
      }).then(({ data }: { data: any }) => {
        if (data["status"] === 401) {
          logout();
          this.props.history.push("/?error=1");
        } else if (data["admin"]) {
          localStorage.setItem("admin", data["admin"]);
        }
      });
    }
    //});
  }

  render() {
    if (localStorage.getItem("jwt") && localStorage.getItem("jwt") !== "") {
      return this.props.children;
    } else {
      return <Redirect to="/"></Redirect>;
    }
  }
}

export default withRouter(Auth);
